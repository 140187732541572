.saleDetails {
  margin: 0 auto 0px;
  max-width: calc(100% - 30px);
}

.saleDetailsList {
  padding: 0px 0px 30px;
  border-radius: 5px;
  position: relative;
}
.saleDetailsList .table-responsive {
  overflow: auto;
}
.saleDetailsList .tabletop {
  max-width: 500px;
  border: 1px solid #af59d7;
  margin: 0 auto;
  min-height: 153px;
}
.saleDetailsList table {
  width: 100%;
}
.saleDetailsList table thead {
  position: sticky;
  top: 0px;
}
.saleDetailsList table thead tr {
  color: #fff;
}
.saleDetailsList table th {
  background-color: #af59d7;
  padding: 15px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
}
.saleDetailsList table td {
  font-size: 16px;
  padding: 10px 15px;
  text-align: center;
}
.saleDetailsList table tr:nth-child(even) {
  background-color: rgba(222, 216, 225, 0.0588235294);
}
.saleDetailsList table tr:nth-last-child(1) td {
  border-bottom: none;
}

@media (max-width: 575px) {
  .balanceOverviewBox {
    padding: 20px;
  }
}/*# sourceMappingURL=SaleDetailList.css.map */