// Footer Css Start
footer {
    text-align: center;
    padding: 30px 15px;

    .socialIcons {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
            margin: 0px 8px;


            a {
                width: 55px;
                height: 55px;
                border-radius: 5px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                // border: 1px solid #3d3f4d;
                background-color: #af59d7;

                &:hover {
                    border-color: #ffffff;
                }
            }

            svg {
                fill: #fff;
                width: 100%;
                height: 100%;
                object-fit: contain;
                padding: 12px;
            }
        }
    }

    .copyright {
        text-align: center;

        p {
            margin: 0px;
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 575px) {
    footer ul li {
        margin: 0px 3px;
    }

    footer ul li a {
        width: 35px;
        height: 35px;
    }

    footer ul li svg {
        padding: 7px;
    }
}

// Footer Css End