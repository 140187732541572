.accordion{
    --cui-accordion-bg: #02121d;
    --cui-accordion-active-bg:none;
    --cui-accordion-active-color:none;
    --cui-accordion-btn-padding-x: 10px;
}
.balanceOverview {
    margin: 0 auto 60px;

    .accordion-flush .accordion-item {
        border: 1px solid #125d60 !important;
        padding: 40px 30px;
        height: 100%;
        border: 1px solid #125d60;
        border-radius: 10px;
        box-shadow: 0 18px 30px rgba(0, 208, 255, 0.1411764706);
        .accordion-body {
            padding-inline: 10px;
        }

    }
    .accordion-button:not(.collapsed) {
        box-shadow: none;
    }
    .accordion-button:focus {
        box-shadow: none;
    }
    .accordion-button::after {
        display: none;
    }
    .accordion-button {
        .arrow {
            transform:rotate(90deg);
    }
    }
    .accordion-button.collapsed {
        .arrow {
            transform:rotate(0deg);
    }
    }
    
    .title{
        margin: 0;
        display: flex;
        width: 100%;
    }
    .viewbtn {
        text-align: center;
        background-color: #02121d;
        color: #ffffff;
        text-decoration: none;
        padding: 10px 5px;
        border-radius: 5px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        border: 1px solid #125d60;
        font-size: 14px;
        
        &:hover {
            background-color: #01ffff;
            color: #02121d;
        }
    }
}

 .balanceOverviewBox {
    // border: 4px double #125d60;
    // padding: 50px;
    padding: 40px 20px 10px;
    border-radius: 5px;
    background-color: #0a1f2f;
    // box-shadow: 3px 3px 0px #125d60;

    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
        display: flex;
        gap: 13px;
        flex-wrap: wrap;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;

            p {
                margin: 0px;
                color: #fff;

                span {
                    font-size: 12px;
                }
            }

            strong {
                margin-left: 15px;
                color: #02ffff;
                flex: 0 0 auto;

                &.countDown {
                    letter-spacing: 2px;
                }
            }
        }
        li.mainbalance {
            flex-direction: column;
            gap: 15px;
            width: fit-content;
            margin: 0 auto;
            // background: #01ffffa1;
            background: rgb(26 210 210 / 63%);
            padding: 15px;
            min-width: 182px;
            border-radius: 5px;
        }
     
    }

    .buttonGrp {
        text-align: center;
    }
}
.logobox {
    flex: 0 0 15%;
}
.achieveinfo {
    flex: 0 0 85%;
   
}
.socialicons {
    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
    
        li {
            margin: 0px 8px;
    
            a {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                // border: 1px solid rgba(1, 255, 255, 0.322);
    
                &:hover{
                    background-color:#01ffff;
                    svg {
                        fill: #000;
                    }
                }
            }
    
            svg {
                fill: #fff;
                fill: #fff;
                width: 1.3em;
                height: 1.3em;
            }
        }
    }
}
@media (max-width:768px) {
    .balanceOverviewBox ul li.mainbalance {
        width: 100%;
    }
}
@media (max-width: 567px) {
    .balanceOverviewBox {
        padding: 20px;
    }
    .balanceOverview {
        .accordion-flush .accordion-item {
            padding: 30px 15px;
         }
         .accordion-button::after {
            background-size: .875rem ;
        }
    }
}
@media (max-width:420px) {
    .balanceOverview {
         .accordion-flush .accordion-item {
            padding: 30px 5px;
         }
    }
    
}