.saleDetails {
    margin: 0 auto 0px;
    max-width: calc(100% - 30px);
}

.saleDetailsList {
    // border-top: 4px double #125d60;
    // border-bottom: 4px double #125d60;
    padding: 0px 0px 30px;
    border-radius: 5px;
    // background-color: #0a1f2f;
    // box-shadow: 3px 3px 0px #125d60;
    position: relative;

    .table-responsive {
        overflow: auto;
    }

    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 50%;
    //     left: -30px;
    //     transform: translateY(-50%);
    //     width: 100px;
    //     height: 100%;
    //     max-height: 400px;
    //     background-image: url('../../Assets/images/tableLine.svg');
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     background-position: left center;

    // }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: 50%;
    //     right: -30px;
    //     transform: translateY(-50%) scaleX(-1);
    //     width: 100px;
    //     height: 100%;
    //     max-height: 400px;
    //     background-image: url('../../Assets/images/tableLine.svg');
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     background-position: left center;

    // }
    .tabletop {
        max-width: 500px;
        border: 1px solid #af59d7;
        margin: 0 auto;
        min-height: 153px;
    }

    table {
        width: 100%;

        thead {
            position: sticky;
            top: 0px;
            tr{
                color: #fff;
            }
        }

        th {
            // background-color: #125d60;
            background-color: #af59d7;
            padding: 15px;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            word-wrap: break-word;
            word-break: keep-all;
        }

        td {
            font-size: 16px;
            padding: 10px 15px;
            text-align: center;
            // border-bottom: 1px solid #125d60;
        }

        tr {
            &:nth-child(even) {
                background-color: #ded8e10f;
                // background-color: rgb(119 45 155 / 39%);
            }

            &:nth-last-child(1) {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .balanceOverviewBox {
        padding: 20px;
    }
}