.bannerSection .bannerinfo {
  width: auto;
  margin-top: 30px;
  background: linear-gradient(189.57deg, rgb(232, 250, 254) -83.55%, rgb(18, 93, 96) -40.3%, rgb(1, 255, 255) 123.53%, rgb(2, 18, 29) 176.26%);
  border-radius: 15px;
  padding: 35px;
  margin-right: 30px;
}
.bannerSection .bannerinfo .bannertitlearea {
  background-color: rgba(11, 153, 155, 0.8392156863);
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  border-radius: 12px;
}
.bannerSection .bannerinfo .bannertitlearea .bannertitle {
  font-family: "Concert One";
  letter-spacing: 0.08em;
}
.bannerSection .bannerinfo .bannerlogo {
  max-width: 40px;
}
.bannerSection .bannerinfo .bannermainheading {
  margin: 0 0 30px;
  font-size: 48px;
  font-weight: bold;
  text-shadow: -2px -2px 0px #02121d;
}
.bannerSection .bannerinfo .bannerbtn {
  text-align: center;
  background-color: #02121d;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: 1px solid #125d60;
}
.bannerSection .bannerinfo .bannerimage {
  max-width: 600px;
}
.bannerSection .bannerinfo .bannerimage .rocketimg {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.bannerSection .alice-carousel__dots-item:not(.__custom) {
  background-color: #28dbd1;
  border-radius: 2px;
  height: 15px;
  transform: skew(-10deg);
  width: 15px;
  opacity: 0.3;
}
.bannerSection .alice-carousel__dots-item:not(.__custom):hover, .bannerSection .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #28dbd1;
  opacity: 1;
}
@media (max-width: 768px) {
  .bannerSection .bannerinfo {
    margin-top: 0px;
    padding-inline: 5px;
    margin-right: 10px;
  }
  .bannerSection .bannerinfo .bannermainheading {
    font-size: 30px;
  }
}/*# sourceMappingURL=Banner.css.map */