*,
*::before,
*::after {
  box-sizing: border-box;
}

.bg-themedark {
  background-color: #125D60;
}

@font-face {
  font-family: "BudmoJiggler-Regular";
  src: url("../Fonts/BudmoJiggler-Regular.eot");
  src: url("../Fonts/BudmoJiggler-Regular.eot?#iefix") format("embedded-opentype"), url("../Fonts/BudmoJiggler-Regular.woff2") format("woff2"), url("../Fonts/BudmoJiggler-Regular.woff") format("woff"), url("../Fonts/BudmoJiggler-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin-top: 0;
  font-family: "BudmoJiggler-Regular" !important;
}

.bgBody {
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: #a3a3a3;
  text-align: left;
  background: #000000;
  word-break: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
}
body::after {
  content: "";
  background-image: url(../images/bgimg.jpg);
  background-size: 60%;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  filter: brightness(1.5);
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d272ff;
}

/* Handle */
/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Concert One";
  text-transform: uppercase;
  font-weight: 400 !important;
}

h1,
h2 {
  margin-bottom: 25px;
  font-family: "Concert One";
  color: #fff;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0px #fff, 0 0 2px #fff, 0 0 2px #bc13fe, 0 0 2px #bc13fe, 0 0 0px #bc13fe, 0 0 2px #bc13fe, 0 0 11px #BC13FE;
  animation: pulsate 1.5s infinite alternate;
  font-size: 42px;
}

@keyframes pulsate {
  0% {
    text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0px #fff, 0 0 2px #fff, 0 0 2px #ff9984, 0 0 2px #ff9984, 0 0 0px #ff9984, 0 0 2px #ff9984, 0 0 11px #ff9984;
  }
  50% {
    text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0px #fff, 0 0 2px #fff, 0 0 2px #ff84a7, 0 0 2px #ff84a7, 0 0 0px #ff84a7, 0 0 2px #ff84a7, 0 0 11px #ff84a7;
  }
  100% {
    text-shadow: 0 0 7px rgba(0, 0, 0, 0), 0 0 0px #fff, 0 0 2px #fff, 0 0 2px #a1ffb8, 0 0 2px #a1ffb8, 0 0 0px #a1ffb8, 0 0 2px #a1ffb8, 0 0 11px #a1ffb8;
  }
}
.bgVideoBody {
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  z-index: -1;
  opacity: 0.1;
}

.bgVideoBody video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.back-btn {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  background-color: #000109;
  text-decoration: none;
  padding: 5px 5px;
  display: flex;
  margin: 0 20px;
  border: none;
  margin-left: -51px;
}

.back-btn:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
  border-radius: 6px;
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.cursor {
  width: 20px;
  height: 20px;
  border: 1px solid white;
  border-radius: 50%;
  position: absolute;
  transition-timing-function: ease-out;
  pointer-events: none;
  z-index: 99;
}

.cursoreffect {
  content: "";
  width: 40px;
  height: 40px;
  border: 1px solid #fd30ff;
  box-shadow: 0 0 20px #fd30ff, 0 0 40px #fd30ff, 0 0 80px #fd30ff;
  border-radius: 50%;
  position: absolute;
  animation: cursorPulse 1s;
}

@keyframes cursorPulse {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(3);
    opacity: 0;
  }
}
img {
  max-width: 100%;
  height: auto;
}

.bg-themedark {
  background-color: #125D60;
}

.bg-themelight {
  background-color: #01FFFF;
}

.oc_row {
  display: flex;
  flex-wrap: wrap;
}

.align-items-flex-end {
  align-items: flex-end;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-flex-start {
  align-items: flex-start;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.oc_col6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 10px;
}

.oc_container {
  width: 1320px;
  max-width: calc(100% - 10vw);
  margin-left: auto;
  margin-right: auto;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.title {
  text-align: center;
  padding: 0;
  margin: 0px 0px 20px;
  border: none;
}
.title h2 {
  text-align: center;
  display: block;
  margin: 0px;
  color: #ffffff;
  text-transform: capitalize;
}

.buttonGrp {
  margin-top: 15px;
}

.oc_btn {
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  margin: 15px 7.5px 15px;
  min-width: 180px;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
}
.oc_btn:hover {
  opacity: 0.8;
}
.oc_btn:nth-last-child(1) {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  font-weight: 400;
}
.oc_btn.copyRefferalCode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}
.oc_btn.copyRefferalCode img {
  height: auto;
  width: auto;
  margin-left: 10px;
  max-height: 19px;
  max-width: 20px;
  filter: invert(1);
}

.borderboxmain {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: -1;
}
.borderboxmain img {
  width: 97vw;
  height: auto;
  display: block;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
}
.borderboxmain img.linetopleft {
  top: 1vw;
  left: 1vw;
  -o-object-position: left;
     object-position: left;
}
.borderboxmain img.linebottomright {
  bottom: 1vw;
  right: 1vw;
  -o-object-position: right;
     object-position: right;
}

.main {
  padding: 200px 5vw 10px;
  position: relative;
  min-height: 1000px;
}

.tooltipIcon {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: bold;
  border: none;
  margin-left: 6px;
  font-size: 16px;
  line-height: 1;
}

@media only screen and (max-width: 992px) {
  .main {
    padding: 150px 2vw 5vw;
  }
}
@media only screen and (max-width: 767px) {
  .buttonGrp {
    text-align: center;
    margin-top: 15px;
  }
  .oc_container {
    max-width: calc(100% - 15px);
  }
  .back-btn {
    margin: 20px 20px 20px;
    font-size: 18px;
  }
  .reflink {
    font-size: 16px;
  }
  .spon {
    margin: 0 auto;
  }
  .main {
    min-height: auto;
  }
  .publicSale .imageBox {
    margin: 50px auto 0px;
    max-width: 300px;
  }
  .oc_btn.copyRefferalCode {
    font-size: 16px;
  }
  .oc_btn:nth-last-child(1) {
    font-size: 16px;
  }
  .cursoreffect {
    display: none;
  }
  .cursor {
    display: none;
  }
}
@media (max-width: 450px) {
  .buySell .buySellbox .oc_btn {
    display: block;
    width: 100%;
  }
  .buyOc .buyOcbox .oc_btn {
    display: block;
    width: 100%;
  }
}
@media (max-width: 390px) {
  .main {
    padding: 120px 2vw 5vw;
  }
  .card {
    max-width: 20.5vw;
  }
}
.loader-wrapper {
  width: 60px;
  height: 60px;
}

.loader {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 10px solid #162534;
  border-top-color: #4bc8eb;
  border-bottom-color: #f13a8f;
  border-radius: 50%;
  animation: rotate0925 5s linear infinite;
}

.loader-inner {
  border-top-color: #36f372;
  border-bottom-color: #fff;
  animation-duration: 2.5s;
}

.infosecbox {
  max-width: 1250px;
  margin: 0 auto;
  padding: 30px;
  border: 2px solid #af59d7;
  margin-top: 50px;
}
.infosecbox h2 {
  text-align: center;
}

@keyframes rotate0925 {
  0% {
    transform: scale(1) rotate(360deg);
  }
  50% {
    transform: scale(0.8) rotate(-360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}/*# sourceMappingURL=style.css.map */