
    .modal-content {
        color: #ffffff;
        border: 1px solid #daa629;
        --cui-modal-bg: #02121d;
        --cui-modal-border-color: rgb(1 127 138);

        .modal-header {
            align-items: flex-start;
            border: none;
            padding: 20px;
        }

        .modal-body {
            padding: 10px;
        }

        button.btn-close {
            outline: none;
            box-shadow: none !important;
            opacity: 1;
            filter: invert(1);
        }

        .modal-title {
            font-size: 24px;
            margin-bottom: 0px;
            color: #ffffff;
            text-shadow: 0.3px 0.3px 0.3px #111;

            p {
                font-size: 14px;
                line-height: 1.2;
                font-weight: 400;
                margin: 10px 0px 0px;
                text-shadow: none;
            }
        }
    }

    .popupbtngrp {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .btngrp {
            flex-basis: calc(33.33% - 20px);
            max-width: calc(33.33% - 20px);
            margin: 0px 10px 20px;
        }

        button,
        a {
            border: 1px solid #092e3b;
            color: #ffffff;
            font-size: 16px;
            padding: 20px 8px;
            border-radius: 5px;
            letter-spacing: 1px;
            line-height: 1;
            display: block;
            text-decoration: none;
            box-shadow: inset 0px 0px 20px rgb(9 46 59);
            position: relative;
            overflow: hidden;
            text-align: center;
            width: 100%;
            background: #02121d;

            img {
                display: block;
                margin: 0 auto 15px;
                height: 45px;
                width: auto;
                // filter: hue-rotate(320deg);
            }

            span {
                display: block;
            }

        }
    }
    @media (max-width:567px) {
        .popupbtngrp {
            button,
            a {
                font-size: 14px;
                padding: 15px 6px;
            }
        }
        
    }
