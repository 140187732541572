.progressbar {
  height: 30px !important;
  background-color: #125d60 !important;
  border-radius: 5px;
}
.progressbar .MuiLinearProgress-dashed {
  background-image: radial-gradient(rgb(18, 93, 96) 0%, rgb(18, 93, 96) 16%, transparent 42%);
}
.progressbar .MuiLinearProgress-bar1Buffer {
  background-color: #02ffff;
}
.progressbar .MuiLinearProgress-bar2Buffer {
  background-color: transparent;
}/*# sourceMappingURL=Linearprogressbar.css.map */