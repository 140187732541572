.progress {
  --cui-progress-height: 30px;
  --cui-progress-bg: #125d60;
  --cui-progress-bar-bg: #02ffff57;
}

.buySell {
  margin: 60px auto 60px;
}
.buySell .buySellbox {
  padding: 30px;
  height: 100%;
  background-color: #02121d;
  border: 1px solid #125d60;
  border-radius: 10px;
  box-shadow: 0 18px 30px rgba(0, 208, 255, 0.1411764706);
}
.buySell .buySellbox .topttl {
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 8px;
  text-align: right;
}
.buySell .buySellbox .fillBox {
  display: block;
  width: 100%;
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.161);
  border: none;
  border-radius: 5px;
  margin-bottom: 8px;
  font-size: 20px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}
.buySell .buySellbox .fillBox span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #02ffff;
}
.buySell .buySellbox .buttonGrp {
  text-align: center;
  width: 100%;
}
.buySell .buySellbox .oc_btn {
  min-width: auto;
  display: inline-block;
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: -3.5rem;
  }
}/*# sourceMappingURL=BuySell.css.map */