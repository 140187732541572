.refDetails {
    margin: 0 auto 80px;
    max-width: calc(100% - 30px);
}

.refDetailsList {
   

    thead {
        position: sticky;
        top: 0px;
        z-index: 99;
    }
   tr:nth-child(even) {
        background-color: rgba(222, 216, 225, 0.0588235294);
    }
    .table-responsive {
        border: 2px solid #af59d7;
        border-radius: 5px;
        position: relative;
        max-width: 700px;
        margin: 0 auto;
        min-height: 153px;
        overflow: auto;
        height: 450px;
    }

    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 50%;
    //     left: -30px;
    //     transform: translateY(-50%);
    //     width: 100px;
    //     height: 100%;
    //     max-height: 400px;
    //     background-image: url('../../Assets/images/tableLine.svg');
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     background-position: left center;

    // }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: 50%;
    //     right: -30px;
    //     transform: translateY(-50%) scaleX(-1);
    //     width: 100px;
    //     height: 100%;
    //     max-height: 400px;
    //     background-image: url('../../Assets/images/tableLine.svg');
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     background-position: left center;

    // }

    table {

        th {
            background-color: #af59d7;
            padding: 15px;
            font-weight: 500;
            font-size: 18px;
            color: #ffffff;
        }

        td {
            font-size: 16px;
            padding: 10px 15px;
        }
        td{
            &[colspan="4"]{
                text-align: center;
            }
        }
        tr {
            &:nth-last-child(1) {
                td {
                    border-bottom: none;
                }
            }
        }
    }
}

.claimBonus {
    .buttonGrp {
        margin: 0 auto;
        text-align: center;

        button {
            background-color: #02121d;
            color: #ffffff;
            border: 1px solid #125d60;

            &:hover {
                background-color: #28dbd1;
                color: #000;
            }
        }
    }
}

@media (max-width: 575px) {
    .balanceOverviewBox {
        padding: 20px;
    }
}



.sponser{
    border: 2px solid #af59d7;
    border-radius: 5px;
    padding: 10px;
    width: fit-content;
    margin: 15px auto
}


.myPagination{
    background-color: #af59d7;
    width: fit-content;
    margin: 0 auto;
    margin-top: 30px;
}