@media only screen and (max-width: 992px) {
  .main {
    padding: 150px 2vw 5vw;
  }


}

@media only screen and (max-width: 767px) {
  .buttonGrp {
    text-align: center;
    margin-top: 15px;
  }

  .oc_container {
    max-width: calc(100% - 15px);
  }



  .back-btn {
    margin: 20px 20px 20px;
    font-size: 18px;
  }

  .reflink {
    font-size: 16px;
  }

  .spon {
    margin: 0 auto;
  }

  .main {
    min-height: auto;
  }

  .publicSale .imageBox {
    margin: 50px auto 0px;
    max-width: 300px;
  }

  .oc_btn.copyRefferalCode {
    font-size: 16px;
  }

  .oc_btn:nth-last-child(1) {
    font-size: 16px;
  }

  .cursoreffect {
    display: none;
  }

  .cursor {
    display: none;
  }
}

@media (max-width: 450px) {
  .buySell .buySellbox .oc_btn {
    display: block;
    width: 100%;
  }

  .buyOc .buyOcbox .oc_btn {
    display: block;
    width: 100%;
  }
}

@media (max-width: 390px) {
  .main {
    padding: 120px 2vw 5vw;
  }

  .card {
    max-width: 20.5vw;
  }
}