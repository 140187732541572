.accordion {
  --cui-accordion-bg: #02121d;
  --cui-accordion-active-bg:none;
  --cui-accordion-active-color:none;
  --cui-accordion-btn-padding-x: 10px;
  --cui-accordion-body-padding-x: 1.25rem;
  --cui-border-color: transparent;
  --cui-accordion-btn-icon:url(../../Assets/images/downarrow.svg);
  --cui-accordion-btn-active-icon:url(../../Assets/images/downarrow.svg);
}

.statisics {
  margin: 0 0 60px;
}
.statisics .boxinner {
  height: 100%;
}
.statisics .boxinner .accordion-flush .accordion-item {
  border: 1px solid #125d60 !important;
  padding: 40px 30px;
  height: 100%;
  border: 1px solid #125d60;
  border-radius: 10px;
  box-shadow: 0 18px 30px rgba(0, 208, 255, 0.1411764706);
}
.statisics .boxinner .accordion-button:not(.collapsed) {
  box-shadow: none;
}
.statisics .boxinner .accordion-button:focus {
  box-shadow: none;
}
.statisics .boxinner .accordion-button::after {
  display: none;
}
.statisics .boxinner .accordion-button .arrow {
  transform: rotate(90deg);
}
.statisics .boxinner .accordion-button.collapsed .arrow {
  transform: rotate(0deg);
}
.statisics .boxinner .stat-info {
  padding: 10px;
}
.statisics .boxinner .stat-info .inputlabel {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #02ffff;
  border: 1px solid #125d60;
  padding: 10px;
  border-radius: 5px;
}
.statisics .boxinner .stat-info .inputlabel p {
  color: #fff;
}
.statisics .boxinner .viewbtn {
  text-align: center;
  background-color: #02121d;
  color: #ffffff;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: 1px solid #125d60;
  font-size: 14px;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.statisics .boxinner .viewbtn:hover {
  background-color: #01ffff;
  color: #02121d;
}
.statisics .boxinner h2 {
  font-size: 28px;
  padding: 0;
  margin: 0px 0px 0px;
  display: block;
  color: #02ffff;
  padding: 5px;
  position: absolute;
  top: -60px;
  background-color: #02121d;
}
.statisics .boxinner .box {
  text-align: center;
  margin: 0 0 30px;
  border-radius: 5px;
  padding: 30px 10px;
  border: 1px solid #125d60;
  box-shadow: 3px 3px 0px #125d60;
}
.statisics .boxinner .box h3 {
  font-size: 18px;
  min-height: 44px;
  color: #02ffff;
  margin: 0 0 15px;
}
.statisics .boxinner .box h5 {
  font-size: 18px;
  color: #02ffff;
  margin: 0 0 15px;
}
.statisics .boxinner .box p {
  margin: 0 0 15px;
  color: #fff;
}
.statisics .boxinner .box strong {
  margin: 0 0 15px;
  display: block;
  color: #125d60;
}
.statisics .boxinner.rightinfo .box {
  border-color: #0a1f2f;
  border: 1px solid #125d60;
  box-shadow: 3px 3px 0px #125d60;
}
.statisics .boxinner.rightinfo .box strong {
  color: #ffffff;
}
.statisics .boxinner.leftinfo {
  margin: 0 0 60px;
}
.statisics .copyReferralLink {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .statisics {
    margin: 0 0 40px;
  }
  .statisics .stat-info .viewbtn {
    max-width: 100%;
  }
  .statisics .boxinner h2 {
    text-align: center;
    color: #02ffef;
  }
  .statisics .boxinner.leftinfo {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 567px) {
  .statisics .boxinner .accordion-flush .accordion-item {
    padding: 30px 5px 0px;
  }
  .statisics .boxinner .accordion-button::after {
    background-size: 0.875rem;
  }
  .statisics .boxinner h2 {
    font-size: 22px;
  }
  .statisics .boxinner h2 {
    top: -50px;
  }
}
@media only screen and (max-width: 420px) {
  .statisics {
    margin: 0 0 30px;
  }
  .statisics .boxinner .accordion-flush .accordion-item {
    padding: 30px 5px 0px;
  }
  .statisics .boxinner h2 {
    font-size: 20px;
  }
  .statisics .boxinner.leftinfo {
    margin-bottom: 30px;
  }
}/*# sourceMappingURL=Statisics.css.map */