.refDetails {
  margin: 0 auto 80px;
  max-width: calc(100% - 30px);
}

.refDetailsList thead {
  position: sticky;
  top: 0px;
  z-index: 99;
}
.refDetailsList tr:nth-child(even) {
  background-color: rgba(222, 216, 225, 0.0588235294);
}
.refDetailsList .table-responsive {
  border: 2px solid #af59d7;
  border-radius: 5px;
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  min-height: 153px;
  overflow: auto;
  height: 450px;
}
.refDetailsList table th {
  background-color: #af59d7;
  padding: 15px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.refDetailsList table td {
  font-size: 16px;
  padding: 10px 15px;
}
.refDetailsList table td[colspan="4"] {
  text-align: center;
}
.refDetailsList table tr:nth-last-child(1) td {
  border-bottom: none;
}

.claimBonus .buttonGrp {
  margin: 0 auto;
  text-align: center;
}
.claimBonus .buttonGrp button {
  background-color: #02121d;
  color: #ffffff;
  border: 1px solid #125d60;
}
.claimBonus .buttonGrp button:hover {
  background-color: #28dbd1;
  color: #000;
}

@media (max-width: 575px) {
  .balanceOverviewBox {
    padding: 20px;
  }
}
.sponser {
  border: 2px solid #af59d7;
  border-radius: 5px;
  padding: 10px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 15px auto;
}

.myPagination {
  background-color: #af59d7;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-top: 30px;
}/*# sourceMappingURL=RefDetails.css.map */