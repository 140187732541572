@import url("https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Krona+One&family=Lobster&family=Mali:wght@200&family=Monoton&family=Proza+Libre&family=Quicksand:wght@500;700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: #1a1c25;
}

.faq {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion {
  --cui-accordion-bg:#283042;
  --cui-accordion-color:#000;
  --cui-accordion-btn-color:rgba(255,255,255,.8);
  --cui-accordion-btn-bg:transparent;
  --cui-accordion-btn-icon-width:.875rem;
  --cui-border-color:#000000;
}

.accordion.accordion-flush {
  max-width: 992px;
  margin: 0 auto;
}

.accordion-item {
  padding-top: 1px;
  padding-bottom: 1px;
  background: #754193;
  cursor: pointer;
  box-shadow: 4.09722px 6.55556px 0px #000000 !important;
  border-radius: 10px;
}

.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none !important;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-body p {
  color: rgba(255, 255, 255, 0.6784313725);
}

button.accordion-button {
  font-size: 18px;
}

.accordion-flush .accordion-item {
  border: none;
  border-radius: 0;
  -webkit-margin-after: 1rem;
          margin-block-end: 1rem;
  border-radius: 10px;
  box-shadow: 0.5rem 2px 0.5rem rgba(0, 0, 0, 0.1);
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 5px;
}

.accordion-button::after {
  filter: hue-rotate(90deg);
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: white;
  box-shadow: none;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.title-box {
  font-family: "KronaOne", sans-serif;
  font-weight: 400;
  line-height: 1.25;
  color: #FFFFFF;
  -webkit-text-stroke: 1.5px #FFFFFF;
  text-align: center;
  font-size: 45px;
  animation: shake 0.25s steps(2) infinite;
  margin-bottom: 40px;
}

@keyframes shake {
  0% {
    transform: none;
  }
  100% {
    transform: skew(1.5deg, 0.2deg) translate(0, -1.5px);
  }
}
@media (min-width: 992px) {
  .accordion-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .title-box {
    font-size: 55px;
  }
}/*# sourceMappingURL=Faq6.css.map */