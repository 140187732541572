.faqs {
  margin: 0 auto 60px;
  max-width: 1100px;
}
.faqs .faq-row-wrapper .faq-title {
  text-align: center;
  justify-content: center;
  padding: 0;
  margin: 0px 0px 40px;
  border: none;
}
.faqs .faq-row-wrapper .faq-title h2 {
  font-size: 28px;
}
.faqs .faq-row-wrapper .faq-body .faq-row {
  margin-bottom: 25px;
  background-color: transparent;
  padding: 0;
  border: none;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 20px;
}
.faqs .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_icon-wrapper__2cftw {
  top: auto;
  right: 2%;
}/*# sourceMappingURL=Faqs.css.map */