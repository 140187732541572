.publicSale {
    margin: 0px 0px 60px;

    .infobox {
        margin-bottom: 15px;
        position: relative;
        z-index: 9;
        h1 {
            margin: 0 0 30px;
            font-size: 74px;
        }

        p {
            span {
                color: #d0a3ff;
                font-size: 22px;
                animation: blink 3s linear infinite;
            }
            @keyframes blink {
                0%{
                    
                transform: scale(0);}
                // 50%{filter: brightness(0.8);}
                100%{
                transform: scale(1.5);
            }

                
            }
        }

        ul {
            padding: 0px;
            margin: 0px 0px 30px;
            list-style: none;

            li {
                margin-bottom: 15px;
            }
        }

        h3 {
            font-size: 20px;
            margin: 0 0 10px;
        }

        .copybox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: #125d60;
            max-width: max-content;
            padding: 12px 15px;
            border-radius: 5px;
            color: #02ffff;

            p {
                margin: 0px;
            }

            svg {
                font-size: 24px;
                margin-left: 20px;
                cursor: pointer;
                fill: #fff43c;
            }
        }
    }

    .imageBox {
        text-align: center;

        img {
            max-width: 500px;
            width: 100%;
            filter: drop-shadow(2px 4px 6px black);
        }

        .bannerVideo {
            max-width: 500px;
            margin: 0 auto;

            video {
                mix-blend-mode: lighten;
                transform: scale(2.2);
                transform-origin: center;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .publicSale {
        text-align: center;
    }

    .publicSale .infobox .copybox {
        margin: 20px auto 0px;
    }
}

@media only screen and (max-width: 1480px) {
    .publicSale .infobox h1 {
        font-size: 40px;
    }
}



.reflink{
    position: relative;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    background-color: #000109 !important;
    text-decoration: none;
    padding: 12px 20px;

    &:hover{
        color: #000109;
         img{
            filter: invert(0) !important;
        }

    }

    &::after{

        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        height: calc(100% + 8px);
        width: calc(100% + 8px);
        background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
        border-radius: 6px;
        z-index: -1;
        animation: animatedgradient 3s ease alternate infinite;
        background-size: 300% 300%;
    }
}
.reflink1{
    position: relative;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000 !important;
    position: relative;
    background-color: transparent !important;
    text-decoration: none;
    padding: 12px 20px;
    border: none !important;

    &:hover{
        color: #000109;
         img{
            filter: invert(0) !important;
        }

    }

    &::after{

        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        height: calc(100% + 8px);
        width: calc(100% + 8px);
        background: linear-gradient(60deg, #ffb46a, #ff9984, #ff84a7, #f09eff, #97baff, #7bedff, #93fff1, #a1ffb8);
        border-radius: 6px;
        z-index: -1;
        animation: animatedgradient 3s ease alternate infinite;
        background-size: 300% 300%;
    }
}


.spon{
    text-align: center;
    width: fit-content;
}